import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Header, Table, Message, Image, Icon } from 'semantic';

import { get } from 'lodash';
import { formatDate } from 'utils/date';
import { groupFieldsByHeadings } from 'utils/notes';

function getValue(object, path, id) {
  return get(object, path ? `${path}.${id}` : id);
}

function renderValue(type, value, field) {
  let valueHtml = <span></span>;

  if (type === 'date') {
    valueHtml = formatDate(value);
  }

  if (type === 'text' && field.enum) {
    valueHtml = value;
  }

  if (type === 'text' && !field.enum) {
    valueHtml = value;
  }

  if (type === 'number') {
    valueHtml = `${value} ${field.unit}`;
  }

  if (type === 'boolean') {
    valueHtml = value ? (
      <span>
        <Icon name="check" color="green" />
      </span>
    ) : (
      <Icon name="close" color="red" />
    );
  }

  if (type === 'image') {
    valueHtml = (
      <Image
        src={field.url}
        style={{ marginTop: '10px', marginBottom: '10px' }}
      />
    );
  }

  return valueHtml;
}

function renderField(object, path, field) {
  const { id, label, type, enum: enumOptions } = field;
  const value = getValue(object, path, id);
  const valueHtml = renderValue(type, value, field);

  let heading = 'h5';
  if (type === 'heading') {
    heading = 'h3';
  }

  if (type === 'image') {
    heading = undefined;
  }

  return (
    <div key={id}>
      {heading && <Header as={heading}>{label}</Header>}
      {valueHtml}
    </div>
  );
}

class FieldsByDefinition extends PureComponent {
  static propTypes = {
    formFields: PropTypes.array.isRequired,
    object: PropTypes.object.isRequired,
    path: PropTypes.string,
  };

  render() {
    const { formFields, object, setField, path = '' } = this.props;
    const groupedFields = groupFieldsByHeadings(formFields);
    return (
      <React.Fragment>
        {groupedFields.map((field) => {
          if (field.type === 'group') {
            return (
              <div key={field.fields[0]?.id}>
                <Table definition>
                  <Table.Body>
                    {field.fields.map((subField) => {
                      const { id, label, type, enum: enumOptions } = subField;
                      const value = getValue(object, path, id);
                      return (
                        <Table.Row key={id}>
                          <Table.Cell width={10}>{label}</Table.Cell>
                          <Table.Cell>
                            {renderValue(type, value, subField)}
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </div>
            );
          } else {
            return renderField(object, path, field);
          }
        })}
      </React.Fragment>
    );
  }
}

export default FieldsByDefinition;
