import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, Container, Divider } from 'semantic';
import Footer from 'components/Footer';
import { Layout } from 'components';
import Protected from 'components/Protected';
import Sidebar from './Sidebar';
import OrganizationSwitcher from './OrganizationSwitcher';
import { withSession } from 'stores';
import { userHasAccess } from 'utils/permissions';

import logo from 'assets/logo.svg';
import favicon from 'assets/favicon.svg';

@withSession
export default class DashboardLayout extends React.Component {
  render() {
    const { user, organization } = this.context;
    return (
      <Sidebar>
        <Sidebar.Menu>
          <Layout style={{ height: '100%' }}>
            <NavLink style={{ margin: '5px 25px 15px 25px' }} to="/">
              <img style={{ width: '100%' }} src={logo} />
            </NavLink>
            <Layout vertical spread>
              <Sidebar.Item>
                <OrganizationSwitcher />
              </Sidebar.Item>
              <Layout.Group>
                <Sidebar.Header>Dashboard</Sidebar.Header>
              </Layout.Group>
              <Layout.Group>
                <Protected endpoint="systems" scope="organization">
                  <Sidebar.Link to="/systems">
                    <Icon name="plug" />
                    My Systems
                  </Sidebar.Link>
                  <Sidebar.Accordion active="/systems">
                    <Sidebar.Link to="/systems/map">
                      <Icon name="map" />
                      Map
                    </Sidebar.Link>
                    <Sidebar.Link to="/systems/energy">
                      <Icon name="Battery_power" custom />
                      Energy
                    </Sidebar.Link>
                    <Sidebar.Link to="/systems/generator">
                      <Icon name="Generator" custom />
                      Generator
                    </Sidebar.Link>
                  </Sidebar.Accordion>
                </Protected>
                <Protected endpoint="users" scope="organization">
                  <Sidebar.Link to="/team">
                    <Icon name="users" />
                    My Team
                  </Sidebar.Link>
                  <Sidebar.Accordion active="/team">
                    <Sidebar.Link to="/team/invites">
                      <Icon name="envelope" />
                      Invites
                    </Sidebar.Link>
                  </Sidebar.Accordion>
                </Protected>
              </Layout.Group>

              <Layout.Group>
                {userHasAccess(user, {
                  endpoint: 'users',
                  permission: 'read',
                  scope: 'organization',
                  scopeRef: organization,
                }) && <Sidebar.Header>Administration</Sidebar.Header>}
              </Layout.Group>
              <Layout.Group grow overflow>
                <Protected endpoint="organizations">
                  <Sidebar.Link to="/organizations">
                    <Icon name="building" />
                    Organizations
                  </Sidebar.Link>
                </Protected>
                <Protected endpoint="users">
                  <Sidebar.Link to="/users">
                    <Icon name="users" />
                    Users
                  </Sidebar.Link>
                </Protected>
                <Protected endpoint="systems">
                  <Sidebar.Link to="/systems/unlinked">
                    <Icon name="inbox" />
                    Setup Systems
                  </Sidebar.Link>
                </Protected>
              </Layout.Group>

              <Layout.Group>
                <Sidebar.Divider />
                <Sidebar.Link to="/settings">
                  <Icon name="cog" />
                  Settings
                </Sidebar.Link>
                <Sidebar.Link to="/support">
                  <Icon name="question-circle" />
                  Help &amp; Support
                </Sidebar.Link>
                <Sidebar.Link to="/logout">
                  <Icon name="sign-out-alt" />
                  Log Out
                </Sidebar.Link>
              </Layout.Group>
            </Layout>
          </Layout>
        </Sidebar.Menu>
        <Sidebar.Content>
          <Sidebar.Mobile>
            <Layout horizontal spread center>
              <Layout.Group>
                <NavLink to="/">
                  <img src={favicon} height="15" />
                </NavLink>
              </Layout.Group>
              <Layout.Group>
                <Sidebar.Trigger>
                  <Icon name="bars" fitted />
                </Sidebar.Trigger>
              </Layout.Group>
            </Layout>
          </Sidebar.Mobile>
          <Container>
            <main>{this.props.children}</main>
            <Footer />
          </Container>
        </Sidebar.Content>
      </Sidebar>
    );
  }
}
