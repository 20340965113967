import React from 'react';
import { Header, Table, Image, Label } from 'semantic';
import { screen } from 'helpers';
import Menu from './Menu';
import Events from 'components/lists/Events';

@screen
export default class DeviceEvents extends React.Component {
  render() {
    const { device } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        <Events
          title="Events"
          baseParams={{
            system: device.system.id,
            device: device.id,
          }}
        />
      </React.Fragment>
    );
  }
}
