import React from 'react';
import { Link } from 'react-router-dom';
import {
  Header,
  Menu,
  Divider,
  Button,
  Dropdown,
  Confirm,
  Icon,
  Image,
  Popup,
} from 'semantic';
import { NavLink } from 'react-router-dom';
import { Breadcrumbs, Layout } from 'components';
import { ExternalLink } from 'components/Link';
import { getRemoteMonitoringUrl } from 'utils/systems';
import EditSystem from 'modals/EditSystem';
import { request } from 'utils/api';
import SystemStatusIcons from 'components/SystemStatusIcons';
import { screenIsMobilePortrait } from 'utils/mobile';
import { getSystemCoordinates } from 'utils/systems';
import { currentUserIsDemoUser, isDemoUser } from 'utils/permissions';
import remoteControlImage from 'assets/images/remote-control-screenshot-2.png';
import Spacer from 'components/Layout/Spacer';

export default class SystemDetailMenu extends React.Component {
  state = {
    remoteControlTooltipShown: localStorage.getItem(
      'remote-control-tooltip-shown',
      ''
    ),
  };
  render() {
    const { system } = this.props;
    const { remoteControlTooltipShown } = this.state;
    return (
      <React.Fragment>
        <Breadcrumbs
          link={<Link to="/systems">Systems</Link>}
          active={system.name || system.serialNumber}></Breadcrumbs>
        <Layout horizontal center spread className="detail-header">
          <Header as="h1">
            <Header.Content>
              {system.name || system.serialNumber}
            </Header.Content>
          </Header>
          <Layout.Group>
            {!screenIsMobilePortrait() && (
              <React.Fragment>
                <Dropdown button basic text="Settings" className="more">
                  <Dropdown.Menu>
                    <Confirm
                      negative
                      confirmButton="Delete"
                      header={`Are you sure you want to delete "${system.name}"?`}
                      content="All data will be permanently deleted"
                      trigger={
                        <Dropdown.Item
                          content="Delete System"
                          icon="trash"
                          disabled={currentUserIsDemoUser()}
                        />
                      }
                      onConfirm={async () => {
                        await request({
                          method: 'DELETE',
                          path: `/1/systems/${system.id}`,
                        });
                        document.location = '/systems';
                      }}
                    />
                    <EditSystem
                      system={system}
                      trigger={
                        <Dropdown.Item
                          content="Edit System"
                          icon="edit"
                          disabled={currentUserIsDemoUser()}
                        />
                      }
                      onSave={() => {
                        document.location = '/systems/' + system.id;
                      }}
                    />
                  </Dropdown.Menu>
                </Dropdown>
                {!remoteControlTooltipShown ? (
                  <Popup
                    content={
                      <div
                        style={{ display: 'block', width: '350px' }}
                        onMouseEnter={() => {
                          localStorage.setItem(
                            'remote-control-tooltip-shown',
                            'true'
                          );
                          this.setState({ remoteControlTooltipShown: true });
                        }}>
                        <div style={{ float: 'left', marginRight: '1em' }}>
                          <Image src={remoteControlImage} size="small" />
                        </div>
                        Click here to access the remote monitoring and control
                        interface
                      </div>
                    }
                    inverted
                    open={!remoteControlTooltipShown}
                    onClose={() => {
                      localStorage.setItem(
                        'remote-control-tooltip-shown',
                        'true'
                      );
                      this.setState({ remoteControlTooltipShown: true });
                    }}
                    position="bottom left"
                    trigger={
                      <Button
                        primary
                        as={ExternalLink}
                        href={getRemoteMonitoringUrl({
                          systemId: system.id,
                          organizationId: system.organization,
                        })}
                        icon="desktop"
                        title="Remote Monitoring &amp; Control"
                        content="Remote Control"
                      />
                    }
                  />
                ) : (
                  <Popup
                    content={
                      <div style={{ display: 'block', width: '350px' }}>
                        <div style={{ float: 'left', marginRight: '1em' }}>
                          <Image src={remoteControlImage} size="small" />
                        </div>
                        Click here to access the remote monitoring and control
                        interface
                      </div>
                    }
                    inverted
                    position="bottom left"
                    trigger={
                      <Button
                        primary
                        as={ExternalLink}
                        href={getRemoteMonitoringUrl({
                          systemId: system.id,
                          organizationId: system.organization,
                        })}
                        icon="desktop"
                        title="Remote Monitoring &amp; Control"
                        content="Remote Control"
                      />
                    }
                  />
                )}
              </React.Fragment>
            )}
          </Layout.Group>
        </Layout>

        {screenIsMobilePortrait() ? (
          <div style={{ paddingBottom: '20px' }}>
            <SystemStatusIcons system={system} vertical />
          </div>
        ) : (
          <SystemStatusIcons system={system} />
        )}

        {screenIsMobilePortrait() && (
          <div style={{ marginTop: '10px' }}>
            <Button
              primary
              fluid
              as={ExternalLink}
              href={getRemoteMonitoringUrl({
                systemId: system.id,
                organizationId: system.organization,
              })}
              style={{ clear: 'both' }}
              icon="desktop"
              content="Remote Control"
              title="Remote Monitoring &amp; Control"
            />
          </div>
        )}
        <Divider hidden />

        <Menu
          {...(screenIsMobilePortrait()
            ? { vertical: true, fluid: true }
            : { tabular: true })}>
          <Menu.Item
            name="Overview"
            to={`/systems/${system.id}`}
            as={NavLink}
            exact
          />
          <Menu.Item
            name="Remote Control"
            to={`/systems/${system.id}/monitoring`}
            as={NavLink}
            exact
          />
          <Menu.Item
            name="Events"
            to={`/systems/${system.id}/events`}
            as={NavLink}
            exact
          />
          <Menu.Item
            name="Devices"
            to={`/systems/${system.id}/devices`}
            as={NavLink}
            exact
          />
          <Menu.Item
            name="Map"
            to={`/systems/${system.id}/map`}
            as={NavLink}
            exact
            disabled={!getSystemCoordinates(system)}
          />
          <Menu.Item
            name="Notes"
            to={`/systems/${system.id}/notes`}
            as={NavLink}
          />
          <Menu.Item
            name="Diagnostics"
            to={`/systems/${system.id}/diagnostics`}
            as={NavLink}
            exact
          />
        </Menu>
        <Divider hidden />
      </React.Fragment>
    );
  }
}
