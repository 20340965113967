import React from 'react';
import { Button, Loader, Message } from 'semantic';
import { request } from 'utils/api';
import { screen } from 'helpers';
import { SearchProvider } from 'components';
import Menu from './Menu';
import Note from 'components/Note';
import { Link } from 'react-router-dom';

@screen
export default class Notes extends React.Component {
  state = {
    loading: false,
    error: null,
    noteFields: null,
    note: null,
  };

  onDataNeeded = async (params) => {
    const { system } = this.props;
    return await request({
      method: 'POST',
      path: '/1/notes/search',
      body: {
        ...params,
        system: system.id,
      },
    });
  };

  componentDidMount() {
    this.loadFields();
    this.fetchNote();
  }

  componentDidUpdate(lastProps) {
    const { noteId } = this.props.match.params;
    if (noteId !== lastProps.match.params.noteId) {
      this.fetchSystem();
    }
  }

  async fetchNote() {
    const { noteId } = this.props.match.params;
    try {
      this.setState({
        error: null,
        loading: true,
      });
      const { data } = await request({
        method: 'GET',
        path: `/1/notes/${noteId}`,
      });
      this.setState({
        note: data,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  loadFields = async () => {
    this.setState({ loading: true, error: null });
    try {
      const { data: noteFields } = await request({
        method: 'GET',
        path: '/1/notes/fields',
      });
      this.setState({
        loading: false,
        noteFields,
      });
    } catch (error) {
      this.setState({
        loading: false,
        error,
      });
    }
  };

  render() {
    const { system } = this.props;
    const { note, noteFields = {}, loading, error } = this.state;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        {error && <Message error content={error.message} />}
        {loading && <Loader active>Loading</Loader>}
        <Button
          as={Link}
          icon="backward"
          to={`/systems/${system.id}/notes`}
          content="Back to Notes"
        />

        {note && (
          <Note
            key={note.id}
            note={note}
            noteFields={noteFields}
            system={system}
            reload={() => {
              this.fetchNote();
            }}
          />
        )}
      </React.Fragment>
    );
  }
}
