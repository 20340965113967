import React from 'react';

export const eventSeverities = {
  warning: {
    name: 'Warning',
    icon: 'exclamation-circle',
    color: 'yellow',
  },
  error: {
    name: 'Error',
    icon: 'exclamation-triangle',
    color: 'orange',
  },
};

export const eventStatuses = {
  new: {
    name: 'New',
    icon: 'envelope',
  },
  resolved: {
    name: 'Resolved',
    icon: 'check',
  },
};

export function formatEventDescription(event) {
  return <React.Fragment>{event.message}</React.Fragment>;
}
