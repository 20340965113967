import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Header, Form, Button, Message, Image, Input } from 'semantic';

import DateField from 'components/form-fields/Date';
import { get } from 'lodash';

class FormInputsByDefinition extends PureComponent {
  static propTypes = {
    formFields: PropTypes.array.isRequired,
    object: PropTypes.object.isRequired,
    path: PropTypes.string,
  };

  render() {
    const { formFields, object, setField, path = '' } = this.props;
    // Dynamically render form fields based on the formFields prop
    return (
      <React.Fragment>
        {formFields.map((field) => {
          const {
            id,
            label,
            type,
            required,
            enum: enumOptions,
            comment,
          } = field;
          const value = get(object, path ? `${path}.${id}` : id);
          const name = path ? `${path}.${id}` : id;

          const fieldProps = {
            key: id,
            required,
            name,
            label,
            value,
            onChange: setField,
          };

          if (comment) {
            fieldProps.label = `${label} (${comment})`;
          }

          if (type === 'subheading') {
            return (
              <Header key={id} as="h4">
                {label}
              </Header>
            );
          }

          if (type === 'heading') {
            return (
              <Header key={id} as="h3">
                {label}
              </Header>
            );
          }

          if (type === 'image') {
            return (
              <Image
                key={id}
                src={field.url}
                style={{ marginTop: '10px', marginBottom: '10px' }}
              />
            );
          }

          if (type === 'date') {
            return <DateField {...fieldProps} />;
          }

          if (type === 'text' && field.enum) {
            return (
              <Form.Dropdown
                {...fieldProps}
                selection
                options={enumOptions.map((option) => ({
                  key: option,
                  text: option,
                  value: option,
                }))}
              />
            );
          }

          if (type === 'text' && !field.enum) {
            return <Form.Input {...fieldProps} />;
          }

          if (type === 'number') {
            return (
              <div
                key={field.id}
                className={`${required ? 'required' : ''} field`}>
                <label>{label}</label>
                <Input
                  label={{ basic: true, content: field.unit || '' }}
                  labelPosition="right"
                  onChange={setField}
                  value={value}
                  name={name}
                  type="number"
                />
              </div>
            );
          }

          if (type === 'boolean') {
            return (
              <React.Fragment key={id}>
                <Form.Checkbox
                  {...fieldProps}
                  checked={value}
                  comment={comment}
                  onChange={(e, { name, checked }) =>
                    setField(e, { name, value: checked })
                  }
                />
              </React.Fragment>
            );
          }

          return <span key={id}></span>;
        })}
      </React.Fragment>
    );
  }
}

export default FormInputsByDefinition;
