import React from 'react';
import {
  Icon,
  Divider,
  Button,
  Card,
  Image,
  Confirm,
  Label,
  Segment,
} from 'semantic';
import { formatRelativeTime } from 'utils/date';
import { request } from 'utils/api';
import { ExternalLink } from 'components/Link';
import EditNote from 'modals/EditNote';
import ReactMarkdown from 'react-markdown';
import { urlForUpload } from 'utils/uploads';
import FieldsByDefinition from 'components/FieldsByDefinition';
import { noteTypes } from 'utils/notes';
import { truncate } from 'lodash';
import { Link } from 'react-router-dom';
import Spacer from './Layout/Spacer';

export default class Note extends React.Component {
  render() {
    const { system, reload, note, noteFields, summary } = this.props;
    let formFields = noteFields[note.type] || [];

    const maxBodyLength = 300;
    const maxFieldsLength = 0;

    let wasSummarized = false;

    if (summary && note.body?.length > maxBodyLength) {
      wasSummarized = true;
    }
    if (summary && formFields.length > maxFieldsLength) {
      wasSummarized = true;
    }

    if (summary) {
      formFields = formFields.slice(0, maxFieldsLength);
    }

    return (
      <Card fluid key={note.id}>
        <Card.Content>
          <EditNote
            note={note}
            system={system}
            noteFields={noteFields}
            trigger={
              <a style={{ float: 'right', marginLeft: '5px' }}>
                <Icon name="edit" />
              </a>
            }
            onSave={reload}
          />
          <Confirm
            negative
            confirmButton="Delete"
            header={`Are you sure you want to delete this note?`}
            content="All data will be permanently deleted"
            trigger={
              <a style={{ float: 'right' }}>
                <Icon name="trash" />
              </a>
            }
            onConfirm={async () => {
              await request({
                method: 'DELETE',
                path: `/1/notes/${note.id}`,
              });
              reload();
            }}
          />
          <Card.Header>
            {summary ? (
              <Link to={`/systems/${system.id}/notes/${note.id}`}>
                {note.creatorUser.name}
              </Link>
            ) : (
              note.creatorUser.name
            )}
          </Card.Header>
          <Card.Meta>{formatRelativeTime(note.createdAt)}</Card.Meta>
          <Card.Description>
            {note.type !== 'generic' && (
              <Segment>
                {note.type !== 'generic' && note.type && (
                  <Label
                    ribbon
                    size="small"
                    content={noteTypes[note.type]?.name}
                    style={{ marginBottom: '10px' }}
                  />
                )}
                <FieldsByDefinition
                  formFields={formFields}
                  object={note}
                  path="fields"
                />
              </Segment>
            )}

            {(note.images.length > 0 || note.documents.length > 0) && (
              <>
                {note.images.length > 0 && (
                  <>
                    {note.images.map((image) => {
                      return (
                        <p key={image.id}>
                          <ExternalLink href={urlForUpload(image)}>
                            <Image size="medium" src={urlForUpload(image)} />
                          </ExternalLink>
                        </p>
                      );
                    })}
                    <Divider hidden />
                  </>
                )}

                {note.documents.length > 0 && (
                  <ul
                    style={{
                      listStyleType: 'none',
                      paddingLeft: 0,
                    }}>
                    {note.documents.map((document) => {
                      return (
                        <li key={document.id}>
                          <ExternalLink
                            target="_blank"
                            href={urlForUpload(document)}>
                            <Button
                              basic
                              icon="file-pdf"
                              content={document.filename}
                              src={urlForUpload(document)}
                            />
                          </ExternalLink>
                        </li>
                      );
                    })}
                  </ul>
                )}
                <Divider hidden />
              </>
            )}
            <ReactMarkdown>
              {summary
                ? truncate(note.body, { length: maxBodyLength })
                : note.body}
            </ReactMarkdown>

            {wasSummarized && (
              <div>
                <Button
                  as={Link}
                  to={`/systems/${system.id}/notes/${note.id}`}
                  primary
                  content="View Full Note"
                />
              </div>
            )}
          </Card.Description>
        </Card.Content>
      </Card>
    );
  }
}
