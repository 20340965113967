import React from 'react';
import { Link } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import { Loader, Header } from 'semantic';
import { Breadcrumbs } from 'components';
import { request } from 'utils/api';

import Overview from './Overview';
import Monitor from './Monitor';
import Alerts from './Alerts';
import Events from './Events';
import Analytics from './Analytics';

export default class DeviceDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      device: null,
      error: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchDevice();
  }

  componentDidUpdate(lastProps) {
    const { id } = this.props.match.params;
    if (id !== lastProps.match.params.id) {
      this.fetchDevice();
    }
  }

  onSave = () => {
    this.fetchDevice();
  };

  async fetchDevice() {
    const { id } = this.props.match.params;
    try {
      this.setState({
        error: null,
        loading: true,
      });
      const { data } = await request({
        method: 'GET',
        path: `/1/devices/${id}`,
      });
      this.setState({
        device: data,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  render() {
    const { loading, error } = this.state;
    if (loading) {
      return <Loader active>Loading</Loader>;
    } else if (error) {
      return (
        <React.Fragment>
          <Breadcrumbs
            link={<Link to="/devices">Devices</Link>}
            active="Not Found"
          />
          <Header content="Sorry that device wasn't found." />
        </React.Fragment>
      );
    }

    return (
      <Switch>
        <Route
          exact
          path="/systems/:systemId/devices/:id"
          render={(props) => (
            <Overview {...props} {...this.state} onSave={this.onSave} />
          )}
        />
        <Route
          exact
          path="/systems/:systemId/devices/:id/monitor"
          render={(props) => (
            <Monitor {...props} {...this.state} onSave={this.onSave} />
          )}
        />
        <Route
          exact
          path="/systems/:systemId/devices/:id/analytics"
          render={(props) => (
            <Analytics {...props} {...this.state} onSave={this.onSave} />
          )}
        />
        <Route
          exact
          path="/systems/:systemId/devices/:id/alerts"
          render={(props) => (
            <Alerts {...props} {...this.state} onSave={this.onSave} />
          )}
        />
        <Route
          exact
          path="/systems/:systemId/devices/:id/events"
          render={(props) => (
            <Events {...props} {...this.state} onSave={this.onSave} />
          )}
        />
      </Switch>
    );
  }
}
