import React from 'react';
import { Modal, Form, Button, Message } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';

import ReferenceField from 'components/form-fields/Reference';
import { createDropdownOptions } from 'utils/form';
import { systemTypes } from 'utils/systems';
import Protected from 'components/Protected';
import AddressField from 'components/form-fields/Address';

export default class EditSystem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      error: null,
      loading: false,
      system: props.system || {},
    };
  }

  componentDidUpdate(lastProps) {
    const { system } = this.props;
    if (system && system !== lastProps.system) {
      this.setState({
        system,
      });
    }
  }

  isUpdate() {
    return !!this.props.system;
  }

  setField = (evt, { name, value }) => {
    this.setState({
      system: {
        ...this.state.system,
        [name]: value,
      },
    });
  };

  setCheckedField = (evt, { name, checked }) => {
    this.setField(evt, { name, value: checked });
  };

  onSubmit = async () => {
    try {
      this.setState({
        loading: true,
      });
      const { system } = this.state;
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/systems/${system.id}`,
          body: {
            ...system,
            ...(this.props.organization && {
              organization: this.props.organization.id,
            }),
            ...(this.props.organization && {
              organization: this.props.organization.id,
            }),
          },
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/systems',
          body: {
            ...system,
            ...(this.props.organization && {
              organization: this.props.organization.id,
            }),
            ...(this.props.organization && {
              organization: this.props.organization.id,
            }),
          },
        });
        this.setState({
          system: {},
        });
      }
      this.setState({
        open: false,
        loading: false,
      });
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { trigger } = this.props;
    const { system, open, loading, error } = this.state;
    return (
      <Modal
        closeIcon
        open={open}
        trigger={trigger}
        closeOnDimmerClick={false}
        onOpen={() => this.setState({ open: true })}
        onClose={() => this.setState({ open: false })}>
        <Modal.Header>
          {this.isUpdate() ? `Edit ${system.name || 'System'}` : 'New System'}
        </Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form
              noValidate
              id="edit-system"
              error={!!error}
              onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}
              <Form.Input
                type="text"
                name="name"
                label="Name"
                value={system.name || ''}
                onChange={this.setField}
              />
              <Form.Dropdown
                selection
                name="type"
                label="Type"
                value={system.type || ''}
                options={createDropdownOptions(systemTypes)}
                onChange={this.setField}
              />
              <Form.Dropdown
                search
                selection
                multiple
                allowAdditions
                name="tags"
                label="Tags"
                options={
                  system.tags?.map((value) => {
                    return {
                      value,
                      text: value,
                    };
                  }) || []
                }
                onChange={this.setField}
                value={system.tags || []}
              />
              <Protected scope="global" endpoint="organizations">
                {!this.props.organization && (
                  <ReferenceField
                    name="organization"
                    label="Organization"
                    value={system.organization}
                    onChange={this.setField}
                    resource="organizations"
                    regex
                  />
                )}
                {!this.props.maintenanceOrganization && (
                  <ReferenceField
                    name="maintenanceOrganization"
                    label="Maintenance Organization"
                    value={system.maintenanceOrganization}
                    onChange={this.setField}
                    resource="organizations"
                    regex
                  />
                )}

                <Form.Checkbox
                  name="disableRealtimeLocation"
                  checked={system.disableRealtimeLocation}
                  label="Disable real-time location tracking"
                  onChange={this.setCheckedField}
                />
                <AddressField
                  value={system.address}
                  onChange={this.setField}
                  name="address"
                  autoComplete="off"
                />
              </Protected>

              <Form.Input
                type="text"
                name="notificationsEmail"
                label="Email address or list for maintenance notifications"
                value={system.notificationsEmail || ''}
                onChange={this.setField}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="edit-system"
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
