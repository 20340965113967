import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import { Loader, Header } from 'semantic';
import { Breadcrumbs } from 'components';
import { request } from 'utils/api';
import { withSession } from 'stores';

import Overview from './Overview';
import Alerts from './Alerts';
import Events from './Events';
import Devices from './Devices';
import Map from './Map';
import Notes from './Notes';
import ViewNote from './ViewNote';
import Diagnostics from './Diagnostics';
import Monitoring from './Monitoring';

@withSession
export default class SystemDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      system: null,
      error: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchSystem();
  }

  componentDidUpdate(lastProps) {
    const { id } = this.props.match.params;
    if (id !== lastProps.match.params.id) {
      this.fetchSystem();
    }
  }

  onSave = () => {
    this.fetchSystem();
  };

  async fetchSystem() {
    const { id } = this.props.match.params;
    try {
      this.setState({
        error: null,
        loading: true,
      });
      const { data } = await request({
        method: 'GET',
        path: `/1/systems/${id}`,
      });
      this.setState({
        system: data,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  render() {
    const { loading, error, system } = this.state;
    if (loading || !system) {
      return <Loader active>Loading</Loader>;
    } else if (error) {
      return (
        <React.Fragment>
          <Breadcrumbs
            link={<Link to="/systems">Systems</Link>}
            active="Not Found"
          />
          <Header content="Sorry that system wasn't found." />
        </React.Fragment>
      );
    }

    const { organization } = this.context;
    if (system.organization && organization.id !== system.organization.id) {
      return <Redirect to="/systems" />;
    }

    return (
      <Switch>
        <Route
          exact
          path="/systems/:id"
          render={(props) => (
            <Overview {...props} {...this.state} onSave={this.onSave} />
          )}
        />
        <Route
          exact
          path="/systems/:id/monitoring"
          render={(props) => (
            <Monitoring {...props} {...this.state} onSave={this.onSave} />
          )}
        />
        <Route
          exact
          path="/systems/:id/alerts"
          render={(props) => (
            <Alerts {...props} {...this.state} onSave={this.onSave} />
          )}
        />
        <Route
          exact
          path="/systems/:id/events"
          render={(props) => (
            <Events {...props} {...this.state} onSave={this.onSave} />
          )}
        />
        <Route
          exact
          path="/systems/:id/devices"
          render={(props) => (
            <Devices {...props} {...this.state} onSave={this.onSave} />
          )}
        />
        <Route
          exact
          path="/systems/:id/notes/:noteId"
          render={(props) => (
            <ViewNote {...props} {...this.state} onSave={this.onSave} />
          )}
        />
        <Route
          exact
          path="/systems/:id/notes"
          render={(props) => (
            <Notes {...props} {...this.state} onSave={this.onSave} />
          )}
        />
        <Route
          exact
          path="/systems/:id/map"
          render={(props) => (
            <Map {...props} {...this.state} onSave={this.onSave} />
          )}
        />
        <Route
          exact
          path="/systems/:id/diagnostics"
          render={(props) => (
            <Diagnostics {...props} {...this.state} onSave={this.onSave} />
          )}
        />
      </Switch>
    );
  }
}
