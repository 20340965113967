import React from 'react';
import {
  Icon,
  Divider,
  Button,
  Card,
  Image,
  Confirm,
  Label,
  Segment,
} from 'semantic';
import { formatRelativeTime } from 'utils/date';
import { request } from 'utils/api';
import { screen } from 'helpers';
import { SearchProvider } from 'components';
import { ExternalLink } from 'components/Link';
import Menu from './Menu';
import EditNote from 'modals/EditNote';
import ReactMarkdown from 'react-markdown';
import { urlForUpload } from '../../../utils/uploads';
import FieldsByDefinition from 'components/FieldsByDefinition';
import { noteTypes } from 'utils/notes';
import Note from 'components/Note';

@screen
export default class Notes extends React.Component {
  state = {
    loading: false,
    error: null,
    noteFields: null,
  };

  onDataNeeded = async (params) => {
    const { system } = this.props;
    return await request({
      method: 'POST',
      path: '/1/notes/search',
      body: {
        ...params,
        system: system.id,
      },
    });
  };

  componentDidMount() {
    this.loadFields();
  }

  loadFields = async () => {
    this.setState({ loading: true, error: null });
    try {
      const { data: noteFields } = await request({
        method: 'GET',
        path: '/1/notes/fields',
      });
      this.setState({
        loading: false,
        noteFields,
      });
    } catch (error) {
      this.setState({
        loading: false,
        error,
      });
    }
  };

  render() {
    const { system } = this.props;
    const { noteFields = {} } = this.state;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        <SearchProvider onDataNeeded={this.onDataNeeded}>
          {({ items: notes, reload }) => {
            return (
              <React.Fragment>
                <EditNote
                  system={system}
                  trigger={<Button primary content="New Note" icon="pen" />}
                  noteFields={noteFields}
                  onSave={reload}
                />
                {notes.map((note) => {
                  return (
                    <Note
                      reload={reload}
                      system={system}
                      key={note.id}
                      note={note}
                      noteFields={noteFields}
                      summary
                    />
                  );
                })}
              </React.Fragment>
            );
          }}
        </SearchProvider>
      </React.Fragment>
    );
  }
}
