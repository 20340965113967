import React from 'react';
import { startCase } from 'lodash';
import { Link } from 'react-router-dom';
import { Table, Divider, Icon, Message, Button } from 'semantic';
import { formatRelativeTime } from 'utils/date';
import { request } from 'utils/api';
import { HelpTip, SearchProvider, Layout } from 'components';

import Filters from 'modals/Filters';
import {
  eventSeverities,
  eventStatuses,
  formatEventDescription,
} from 'utils/events';
import { createDropdownOptions } from 'utils/form';

export default class Events extends React.Component {
  onDataNeeded = async (params) => {
    const { baseParams } = this.props;
    return await request({
      method: 'POST',
      path: '/1/events/search',
      body: {
        ...params,
        ...baseParams,
      },
    });
  };

  async resolve(item) {
    await request({
      method: 'PATCH',
      path: '/1/events/' + item.id,
      body: {
        status: 'resolved',
      },
    });
  }

  async unresolve(item) {
    await request({
      method: 'PATCH',
      path: '/1/events/' + item.id,
      body: {
        status: 'new',
      },
    });
  }

  render() {
    const { title } = this.props;
    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({
          items: events,
          getSorted,
          setSort,
          filters,
          setFilters,
          reload,
        }) => {
          return (
            <React.Fragment>
              <Layout horizontal center spread>
                <h1>{title}</h1>
                <Layout.Group>
                  <Filters onSave={setFilters} filters={filters}>
                    <Filters.Dropdown
                      search
                      name="severity"
                      label="Severity"
                      options={createDropdownOptions(eventSeverities)}
                    />
                    <Filters.Dropdown
                      search
                      name="status"
                      label="Status"
                      options={createDropdownOptions(eventStatuses)}
                    />
                  </Filters>
                </Layout.Group>
              </Layout>
              <Divider hidden />
              {events.length === 0 ? (
                <Message>No events triggered yet</Message>
              ) : (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Description</Table.HeaderCell>
                      <Table.HeaderCell width={3}>
                        Device / System
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        width={2}
                        sorted={getSorted('severity')}
                        onClick={() => setSort('severity')}
                        textAlign="center">
                        Severity
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        width={2}
                        sorted={getSorted('status')}
                        onClick={() => setSort('status')}
                        textAlign="center">
                        Status
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        width={2}
                        onClick={() => setSort('createdAt')}
                        sorted={getSorted('createdAt')}>
                        Created
                        <HelpTip
                          title="Created"
                          text="This is the date and time the event was created."
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center" width={2}>
                        Actions
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {events.map((event) => {
                      const severityObject = eventSeverities[event.severity];
                      return (
                        <Table.Row
                          key={event.id}
                          style={{
                            opacity: event.status === 'resolved' ? 0.6 : 1,
                          }}>
                          <Table.Cell>
                            {formatEventDescription(event)}
                          </Table.Cell>
                          <Table.Cell>
                            <Link
                              to={`/systems/${event.system.id}/devices/${event.device.id}`}>
                              {event.device.modelName ||
                                event.device.serialNumber}
                            </Link>
                            <p>
                              <small>
                                (
                                <Link to={`/systems/${event.system.id}`}>
                                  {event.system.name ||
                                    event.system.serialNumber}
                                </Link>
                                )
                              </small>
                            </p>
                          </Table.Cell>
                          <Table.Cell
                            textAlign="center"
                            error={event.severity === 'error'}
                            warning={event.severity === 'warning'}>
                            {severityObject && (
                              <React.Fragment>
                                <Icon name={severityObject.icon} />{' '}
                                {severityObject.name}
                              </React.Fragment>
                            )}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {startCase(event.status)}
                          </Table.Cell>
                          <Table.Cell>
                            {formatRelativeTime(event.createdAt)}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {event.status === 'new' && (
                              <Button
                                basic
                                icon="check"
                                title="Resolve this issue"
                                onClick={async () => {
                                  await this.resolve(event);
                                  reload();
                                }}
                              />
                            )}
                            {event.status === 'resolved' && (
                              <Button
                                basic
                                icon="undo"
                                title="Unresolve this issue"
                                onClick={async () => {
                                  await this.unresolve(event);
                                  reload();
                                }}
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
