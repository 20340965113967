import React from 'react';
import { screen } from 'helpers';
import Events from 'components/lists/Events';
import Menu from './Menu';

@screen
export default class SystemEvents extends React.Component {
  render() {
    const { system } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        <Events
          title="Events"
          baseParams={{
            system: system.id,
          }}
        />
      </React.Fragment>
    );
  }
}
