import React from 'react';
import {
  Modal,
  Form,
  Button,
  Message,
  Segment,
  Label,
  Divider,
} from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';
import { modal } from 'helpers';

import UploadsField from 'components/form-fields/Uploads';
import ReferenceField from 'components/form-fields/Reference';
import { createDropdownOptions } from 'utils/form';
import { noteTypes, validateParams } from 'utils/notes';
import FormInputsByDefinition from 'components/FormInputsByDefinition';
import { set } from 'lodash';

@modal
export default class EditNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      note: props.note || {
        type: 'generic',
      },
    };
  }

  isUpdate() {
    return !!this.props.note;
  }

  setField = (evt, { name, value }) => {
    const note = {
      ...this.state.note,
    };
    set(note, name, value);
    this.setState({
      note,
    });
  };

  setCheckedField = (evt, { name, checked }) => {
    this.setField(evt, { name, value: checked });
  };

  onSubmit = async () => {
    this.setState({
      loading: true,
      error: null,
    });
    const { note } = this.state;

    const validationErrors = validateParams(note, this.props.noteFields);
    if (validationErrors.length) {
      this.setState({
        error: {
          message: validationErrors.slice(0, 5).join(', '),
        },
        loading: false,
      });
      return;
    }

    try {
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/notes/${note.id}`,
          body: {
            ...note,
            ...(this.props.system && {
              system: this.props.system.id,
            }),
            ...(this.props.organization && {
              organization: this.props.organization.id,
            }),
          },
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/notes',
          body: {
            ...note,
            ...(this.props.system && {
              system: this.props.system.id,
            }),
            ...(this.props.organization && {
              organization: this.props.organization.id,
            }),
          },
        });
      }
      this.props.onSave();
      this.props.close();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { note, loading, error } = this.state;
    const { noteFields = {} } = this.props;
    const formFields = noteFields[note.type] || [];

    return (
      <>
        <Modal.Header>
          {this.isUpdate() ? `Edit Note` : 'New Note'}
        </Modal.Header>
        <Modal.Content scrolling>
          <AutoFocus>
            <Form
              noValidate
              id="edit-note"
              error={!!error}
              onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}
              {!this.props.system && (
                <ReferenceField
                  required
                  name="system"
                  label="System"
                  value={note.system}
                  onChange={this.setField}
                  resource="Systems"
                />
              )}

              <Form.Dropdown
                selection
                name="type"
                label="Type"
                value={note.type || ''}
                options={createDropdownOptions(noteTypes)}
                onChange={this.setField}
              />

              {note.type !== 'generic' && (
                <Segment>
                  <Label ribbon>{noteTypes[note.type]?.name}</Label>
                  <Divider hidden />
                  <FormInputsByDefinition
                    formFields={formFields}
                    object={note}
                    setField={this.setField}
                    path="fields"
                  />
                </Segment>
              )}

              <UploadsField
                name="images"
                label="Images (Optional)"
                value={note.images || []}
                onChange={this.setField}
                onError={(error) => this.setState({ error })}
              />
              <UploadsField
                name="documents"
                type="pdf"
                label="Documents (Optional)"
                value={note.documents || []}
                onChange={this.setField}
                onError={(error) => this.setState({ error })}
              />
              <Form.Field>
                <a
                  style={{ float: 'right' }}
                  href="https://www.markdownguide.org/basic-syntax/"
                  target="_blank"
                  rel="noopener noreferrer">
                  Formatting
                </a>
                <label>Note</label>
                <Form.TextArea
                  name="body"
                  value={note.body || ''}
                  onChange={this.setField}
                />
              </Form.Field>
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="edit-note"
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
          />
        </Modal.Actions>
      </>
    );
  }
}
