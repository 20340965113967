import React from 'react';
import { Menu, Divider, Button, Dropdown, Confirm } from 'semantic';
import { NavLink, Link } from 'react-router-dom';
import { Breadcrumbs, Layout } from 'components';
import { ExternalLink } from 'components/Link';
import { request } from 'utils/api';
import { getRemoteMonitoringUrl } from 'utils/systems';
import { screenIsMobilePortrait } from 'utils/mobile';
import { currentUserIsDemoUser } from 'utils/permissions';

export default ({ device }) => {
  return (
    <React.Fragment>
      <Breadcrumbs
        path={[
          <Link to="/systems">Systems</Link>,
          <Link to={`/systems/${device.system.id}`}>{device.system.name}</Link>,
          <Link to={`/systems/${device.system.id}/devices`}>Devices</Link>,
        ]}
        active={device.name || device.modelName}></Breadcrumbs>

      <Layout horizontal center spread>
        <h1>{device.name || device.modelName}</h1>
        <Layout.Group>
          {!screenIsMobilePortrait() ? (
            <React.Fragment>
              <Dropdown button basic text="Settings" className="more">
                <Dropdown.Menu>
                  <Confirm
                    negative
                    confirmButton="Delete"
                    header={`Are you sure you want to delete "${device.name}"?`}
                    content="All data will be permanently deleted"
                    trigger={
                      <Dropdown.Item
                        content="Delete Device"
                        icon="trash"
                        disabled={currentUserIsDemoUser()}
                      />
                    }
                    onConfirm={async () => {
                      await request({
                        method: 'DELETE',
                        path: `/1/devices/${device.id}`,
                      });
                      document.location = `/systems/${device.system.id}/devices`;
                    }}
                  />
                </Dropdown.Menu>
              </Dropdown>
              <Button
                primary
                as={ExternalLink}
                href={getRemoteMonitoringUrl({
                  systemId: device.system.id,
                  organizationId: device.system.organization,
                  deviceId: device.id,
                })}
                icon="desktop"
                title="Remote Monitoring &amp; Control"
                content="Remote Control"
              />
            </React.Fragment>
          ) : (
            <Button
              primary
              as={ExternalLink}
              href={getRemoteMonitoringUrl({
                systemId: device.system.id,
                organizationId: device.system.organization,
                deviceId: device.id,
              })}
              icon="desktop"
              title="Remote Monitoring &amp; Control"
            />
          )}
        </Layout.Group>
      </Layout>

      <Menu
        {...(screenIsMobilePortrait()
          ? { vertical: true, fluid: true }
          : { tabular: true })}>
        <Menu.Item
          name="Overview"
          to={`/systems/${device.system.id}/devices/${device.id}`}
          as={NavLink}
          exact
        />
        <Menu.Item
          name="Monitor"
          to={`/systems/${device.system.id}/devices/${device.id}/monitor`}
          as={NavLink}
          exact
        />
        <Menu.Item
          name="Events"
          to={`/systems/${device.system.id}/devices/${device.id}/events`}
          as={NavLink}
          exact
        />
        <Menu.Item
          name="Analyticsᵇᵉᵗᵃ"
          to={`/systems/${device.system.id}/devices/${device.id}/analytics`}
          as={NavLink}
          exact
        />
      </Menu>
      <Divider hidden />
    </React.Fragment>
  );
};
