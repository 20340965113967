export const noteTypes = {
  generic: {
    name: 'Generic',
  },
  'service-report': {
    name: 'Service Report',
  },
  'generator-registration': {
    name: 'Generator Registration',
  },
  'generator-commissioning': {
    name: 'Generator Commissioning',
  },
};

export const validateParams = (note, noteFields) => {
  const formFields = noteFields[note.type] || [];
  const errors = [];
  formFields.forEach((field) => {
    const fields = note.fields || {};
    if (field.required && !fields[field.id]) {
      errors.push(`${field.label} is required`);
    }
  });
  return errors;
};

export function groupFieldsByHeadings(fields, options = {}) {
  const newFields = [];
  let currentGroup = [];
  for (const field of fields) {
    if (['heading', 'subheading', 'image'].includes(field.type)) {
      if (currentGroup.length) {
        newFields.push({
          type: 'group',
          fields: currentGroup,
        });
        currentGroup = [];
      }
      newFields.push(field);
    } else {
      currentGroup.push(field);
    }
  }
  if (currentGroup.length) {
    newFields.push({
      type: 'group',
      fields: currentGroup,
    });
  }
  return newFields;
}
